import { createSelector } from 'reselect';
import { AsyncStateError, ShimState } from 'state-domains/types';

import { AzureMapTokenState } from '../types';

const azureMapTokenState = (state: Partial<ShimState>): AzureMapTokenState => {
    const { azureMapToken = {} as AzureMapTokenState } = state || {};
    return azureMapToken;
};

const azureMapAccessTokenSelector = createSelector(
    azureMapTokenState,
    ({ accessToken }: AzureMapTokenState): string => accessToken,
);

const azureMapClientIdSelector = createSelector(
    azureMapTokenState,
    ({ azureClientId }: AzureMapTokenState): string => azureClientId,
);

export const azureMapsTokenExpirySelector = createSelector(
    azureMapTokenState,
    ({ expiry }: AzureMapTokenState): number => expiry,
);

export const azureMapsErrorSelector = createSelector(
    azureMapTokenState,
    ({ error }: AzureMapTokenState): AsyncStateError | null => error,
);

export const selectors = {
    azureMapTokenState,
    azureMapAccessTokenSelector,
    azureMapClientIdSelector,
    azureMapsTokenExpirySelector,
    azureMapsErrorSelector,
};
